<template>
  <div class="flex flex-shrink-0 items-center">
    <div
      class="rounded cursor-pointer flex-shrink-0 p-1"
      :class="{
        'bg-accent bg-red border-none': modelValue || half,
        'border border-gray-400': theme === 'light',
        'bg-white border border-gray-400':
          theme === 'light' && !modelValue && !half,
        'bg-transparent-gray': theme === 'dark' && !modelValue && !half,
        'w-4 h-4': small,
        'w-6 h-6': !small,
        'opacity-50 pointer-events-none': disabled
      }"
      @click.stop="$emit('update:model-value', !modelValue)"
    >
      <div class="w-full h-full flex items-center justify-center">
        <div v-if="half" class="text-white font-bold text-2xl">-</div>
        <icon v-else-if="modelValue" name="check" class="text-white" small />
      </div>
    </div>
    <div
      v-if="label"
      class="ml-2 cursor-pointer"
      :class="{ 'text-white': theme === 'dark' }"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/v3/components/Icon.vue'

export default {
  name: 'LCheckbox',
  components: {
    Icon
  },
  props: {
    modelValue: Boolean,
    theme: {
      type: String,
      default: 'light'
    },
    small: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    half: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    }
  },
  emits: ['update:model-value']
}
</script>
