<template>
  <l-modal
    :title="$t('plans.cancel-subscription')"
    @close="$emit('close')"
    :loading="loading"
  >
    <template #body>
      <div class="text-blue ml-2">
        <div class="mb-6">
          <p class="text-2xl mb-2">
            {{ $t('plans.cancel-subscription-reason-question') }}
          </p>
          <p class="text-sm ml-2">
            {{
              $t('plans.cancel-subscription-use-until', {
                date: endOfPeriod
              })
            }}
          </p>
        </div>
        <div v-for="reason in formattedReasons" :key="reason" class="mb-2">
          <l-radio
            :model-value="selectedReason.key === reason.key"
            @update:model-value="selectedReason = reason"
            :label="reason.text"
            label-class="text-blue"
          />
        </div>
        <p class="mt-6 mb-2">{{ $t('plans.cancel-subscription-comments') }}</p>
        <l-input v-model="comment" input-type="textarea" :max-length="1000" />
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end">
        <l-button @click="$emit('close')" border class="mr-4">
          {{ $t('ctas.back') }}
        </l-button>
        <l-button
          @click="confirm"
          :disabled="!selectedReason || !comment"
          class="bg-red"
        >
          {{ $t('plans.cancel-subscription') }}
        </l-button>
      </div>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/v3/components/LModal.vue'
import LRadio from '@last/core-ui/v3/components/LRadio.vue'
import LInput from '@last/core-ui/v3/components/LInput.vue'
import LButton from '@last/core-ui/v3/components/LButton.vue'
import api from '@/api.js'

export default {
  name: 'CancelSubscriptionModal',
  emits: ['close', 'confirm'],
  props: {
    endOfPeriod: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      reasons: [],
      selectedReason: {},
      comment: '',
      loading: true
    }
  },
  async mounted() {
    await this.refreshCancelReasons()
    this.loading = false
  },
  methods: {
    async refreshCancelReasons() {
      let { data } = await api.get('/dashboard/internal-billing/cancel-info')
      this.reasons = data.reasons
    },
    confirm() {
      this.$emit('confirm', {
        reason: this.selectedReason.key,
        comment: this.comment
      })
    }
  },
  computed: {
    formattedReasons() {
      return this.reasons.map(reason => ({
        key: reason,
        text: this.$t(`plans.${reason.toLowerCase().replace(/\s/g, '-')}`)
      }))
    }
  },
  components: {
    LModal,
    LRadio,
    LInput,
    LButton
  }
}
</script>
