<template>
  <div class="px-5 pt-8">
    <l-card
      :title="$t('billing.billing-history')"
      :overflow-visible="true"
      :content-padding="true"
    >
      <div
        class="flex flex-row text-gray-400 text-sm pb-2 border-b border-gray-200"
      >
        <div class="flex-1 text-gray-400">{{ $t('billing.start') }}</div>
        <div class="flex-1">{{ $t('billing.end') }}</div>
        <div class="flex-1">{{ $t('billing.fee') }}</div>
        <div class="flex-1">{{ $t('billing.total') }}</div>
        <div class="flex-2">{{ $t('billing.actions') }}</div>
      </div>
      <div v-for="(billingRow, index) in billingHistory" :key="index">
        <billing-row
          :billing-row="billingRow"
          @refresh="refreshBillingHistory"
        ></billing-row>
      </div>
    </l-card>
    <l-card
      :title="$t('billing.payouts-history')"
      :overflow-visible="true"
      :content-padding="true"
    >
      <div
        class="flex flex-row text-gray-400 text-sm pb-2 border-b border-gray-200"
      >
        <div class="flex-1 text-gray-400">{{ $t('billing.start') }}</div>
        <div class="flex-1">{{ $t('billing.end') }}</div>
        <div class="flex-1">{{ $t('billing.fee') }}</div>
        <div class="flex-1">{{ $t('billing.total') }}</div>
        <div class="flex-2">{{ $t('billing.actions') }}</div>
      </div>
      <div v-for="(payoutRow, index) in payoutHistory" :key="index">
        <payout-row
          :payout-row="payoutRow"
          @refresh="refreshPayoutsHistory"
        ></payout-row>
      </div>
    </l-card>
  </div>
</template>

<script>
import { LCard } from '@last/core-ui/paprika'

import api from '../api'
import BillingRow from './BillingRow.vue'
import PayoutRow from './PayoutRow.vue'

export default {
  name: 'Billing',
  components: {
    LCard,
    BillingRow,
    PayoutRow
  },
  data() {
    return {
      billingHistory: [],
      payoutHistory: []
    }
  },
  async mounted() {
    this.refreshBillingHistory()
    this.refreshPayoutsHistory()
  },
  methods: {
    async refreshBillingHistory() {
      const billingHistoryResponse = await api.get('/support/invoices')
      this.billingHistory = await Promise.all(
        billingHistoryResponse.data.map(async period => {
          const zohoInvoicesResponse = await api.get('/support/invoices/zoho', {
            params: {
              periodId: period.periodId
            }
          })
          return {
            ...period,
            canSendEmail:
              zohoInvoicesResponse.data.length &&
              zohoInvoicesResponse.data.every(invoice => invoice.customerId)
          }
        })
      )
    },
    async refreshPayoutsHistory() {
      const payoutHistoryResponse = await api.get('/support/payouts')
      this.payoutHistory = payoutHistoryResponse.data
    }
  }
}
</script>

<style scoped></style>
