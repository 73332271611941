<template>
  <div id="app">
    <router-view></router-view>
    <l-dialog-wrapper />
    <l-notification-wrapper />
    <div id="appRoot"></div>
  </div>
</template>

<script>
import { LDialogWrapper, LNotificationWrapper } from '@last/core-ui/paprika'

export default {
  name: 'App',
  components: {
    LDialogWrapper,
    LNotificationWrapper
  }
}
</script>

<style></style>
