<template>
  <div class="flex flex-row text-blue py-5 border-b border-gray-200">
    <div class="flex-1">{{ billingRow.periodStart }}</div>
    <div class="flex-1">{{ billingRow.periodEnd }}</div>
    <div class="flex-1">{{ $filters.currency(billingRow.fee) }}</div>
    <div class="flex-1">{{ $filters.currency(billingRow.total) }}</div>
    <div class="flex flex-2">
      <div class="flex-1">
        <div
          v-if="!loading"
          class="text-red underline cursor-pointer"
          @click="uploadInvoiceToZoho(billingRow.periodId)"
        >
          {{ $t('billing.upload-to-zoho') }}
        </div>
        <div v-else class="flex items-center text-gray-400">
          <icon name="loading-spinner" class="animate-spin mr-2" small />
          Cargando...
        </div>
      </div>
      <div
        class="flex-1 text-red underline cursor-pointer"
        :class="{
          'opacity-50 pointer-events-none': !billingRow.canSendEmail
        }"
        @click="sendZohoInvoices(billingRow.periodId)"
      >
        {{ $t('billing.send-mail') }}
      </div>
      <div class="flex-1"></div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@last/core-ui/paprika'

import api from '../api'

export default {
  name: 'BillingRow',
  props: {
    billingRow: {
      type: Object,
      default: null
    }
  },
  components: {
    Icon
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {},
  methods: {
    async uploadInvoiceToZoho(periodId) {
      this.loading = true
      await api.post('/support/invoices/upload', { periodId })
      this.loading = false
      this.$emit('refresh')
    },
    async sendZohoInvoices(periodId) {
      await api.post('/support/invoices/finalize', { periodId })
    }
  }
}
</script>

<style scoped></style>
