import moment from 'moment'

import { lastUtils } from '@last/core'

export const createFilters = () => {
  function date(value) {
    if (value) {
      return moment(value).format('DD/MM/YYYY HH:mm')
    }
  }

  function day(value) {
    if (value) {
      return moment(value).format('DD/MM/YYYY')
    }
  }

  function time(value) {
    if (value) {
      return moment(value).format('HH:mm')
    }
  }

  function currency(value) {
    let currencyCode = 'EUR'
    return lastUtils.currencyFilter(value, currencyCode)
  }

  function currencyMilis(value) {
    let currencyCode = 'EUR'
    return lastUtils.currencyFilter(value, currencyCode, true)
  }

  function ellapsedTime(value) {
    if (value) {
      var now = moment(new Date())
      var start = moment(value)
      var duration = moment.duration(now.diff(start))
      return (
        Math.floor(duration.asHours()) +
        moment.utc(duration.asMilliseconds()).format(':mm')
      )
    }
  }

  return {
    date,
    day,
    time,
    currency,
    ellapsedTime,
    currencyMilis
  }
}
