<template>
  <label>
    <div
      class="w-full h-full flex items-center justify-center overflow-hidden border cursor-pointer text-gray-400"
      :class="{
        'border-violet box-border': dragDropFocus,
        'border-dashed': !dragDropFocus,
        'bg-transparent-gray border-gray-400': theme === 'dark',
        'bg-transparent-light-gray border-gray-300': theme === 'light',
        'cursor-pointer': !disabled,
        'pointer-events-none': disabled,
        'rounded-full': circle,
        'rounded-small': !circle
      }"
      @dragover.prevent="updateDragDropFocus(true)"
      @dragleave.prevent="updateDragDropFocus(false)"
      @dragenter.prevent="updateDragDropFocus(true)"
      @drop.prevent="onFileChange"
    >
      <div v-if="fileNames.length > 0" class="w-full">
        <div
          class="flex items-center justify-between p-2 relative z-10"
          :class="theme === 'dark' ? 'text-n-0' : 'text-neutral-800'"
        >
          <span class="mt-2"> {{ fileNames.join(', ') }}</span>
          <l-icon
            name="close"
            class="pointer-events-auto"
            @click="onFileRemoved"
          />
        </div>
      </div>
      <slot v-else>
        <div class="flex flex-col items-center p-2">
          <l-icon name="import" class="text-violet" />
          <span v-if="text" class="mt-2" v-html="text"></span>
        </div>
      </slot>
    </div>

    <input
      ref="uploadInput"
      class="hidden"
      type="file"
      v-bind="$attrs"
      :multiple="multiple"
      :accept="accept"
      :disabled="disabled"
      @change="onFileChange"
    />
  </label>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

import { LIcon } from '@last/core-ui/paprika'

type Props = {
  modelValue: File | Array<File> | null
  multiple: boolean
  accept: string
  theme: string
  disabled: boolean
  circle: boolean
  text: string
}
defineOptions({
  inheritAttrs: false
})
const emit = defineEmits(['update:modelValue'])
const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  multiple: false,
  accept: '',
  theme: 'light',
  disabled: false,
  circle: false,
  text: ''
})

let newValue = props.modelValue
const dragDropFocus = ref(false)
const loading = ref(false)
const uploadInput = ref<HTMLInputElement | null>(null)
const fileNames = ref<string[]>([])

watch(props.modelValue, (modelValue: File) => {
  const inputFiles = uploadInput.value?.files
  if (!inputFiles) return
  newValue = modelValue
  if (
    !newValue ||
    (Array.isArray(newValue) && newValue.length === 0) ||
    !inputFiles[0] ||
    (Array.isArray(newValue) &&
      !newValue.some(function (a) {
        return a.name === inputFiles[0].name
      }))
  ) {
    uploadInput.value = null
  }
})

function onFileRemoved(event) {
  event.stopPropagation()
  newValue = null
  fileNames.value = []
  emit('update:modelValue', newValue)
}

function onFileChange(event) {
  if (props.disabled || loading.value) return
  updateDragDropFocus(false)
  const files = event.target.files || event.dataTransfer.files
  if (files.length === 0) {
    if (!newValue) {
      return
    }
  } else if (!props.multiple) {
    if (files.length !== 1) return
    else {
      const file = files[0]
      if (checkType(file)) {
        newValue = file
      } else if (newValue) {
        newValue = null
      } else {
        return
      }
    }
  } else {
    let newValues = false
    if (!newValue) {
      newValue = []
      newValues = true
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (checkType(file) && Array.isArray(newValue)) {
        newValue.push(file)
        newValues = true
      }
    }
    if (!newValues) {
      return
    }
  }
  fileNames.value = Array.isArray(newValue)
    ? newValue.map((file: File) => file.name)
    : [newValue?.name || '']
  emit('update:modelValue', newValue)
}

function updateDragDropFocus(focus: boolean) {
  if (!props.disabled && !loading.value) {
    dragDropFocus.value = focus
  }
}

function checkType(file: any) {
  if (!props.accept) return true
  const types = props.accept.split(',')
  for (let i = 0; i < types.length; i++) {
    const type = types[i].trim()
    if (type) {
      if (type.startsWith('.')) {
        // check extension
        const extIndex = file.name.lastIndexOf('.')
        const extension = extIndex >= 0 ? file.name.substring(extIndex) : ''
        if (extension.toLowerCase() === type.toLowerCase()) {
          return true
        }
      } else {
        // check mime type
        if (file.type.match(type)) {
          return true
        }
      }
    }
  }
  return false
}
</script>
