<template>
  <div class="bg-gray-100 px-5 h-full relative">
    <div class="h-full overflow-y-scroll">
      <div
        v-if="selectedOrganization"
        class="p-5 uppercase text-blue font-bold text-xl font-title flex flex-row justify-between"
      >
        <organization-name :organization="selectedOrganization" />
        <div
          class="w-10 h-10 rounded-full bg-red flex justify-center items-center cursor-pointer"
        >
          <icon
            name="trash"
            class="text-white"
            @click="cancelingSubscription = true"
          />
        </div>
      </div>
      <support-access />
      <organization-features />
      <organization-shop-domains />
      <public-api-users />
      <l-card :title="$t('import-catalog.title')">
        <l-tabs :tabs="tabs" v-model="selectedTabId" />
        <div class="mt-4">
          <import-uber-catalog v-if="selectedTabId === 'importUberCatalog'" />
          <import-glovo-catalog v-if="selectedTabId === 'importGlovoCatalog'" />
          <import-klikin-catalog
            v-if="selectedTabId === 'importKlikinCatalog'"
          />
          <import-CSV-catalog v-if="selectedTabId === 'importCSVCatalog'" />
        </div>
      </l-card>
      <organization-billing-options />
      <demo-organization />
      <cancel-subscription-modal
        v-if="cancelingSubscription"
        allow-deletion
        @close="cancelingSubscription = false"
        @confirm="deleteOrganization"
      />
    </div>
    <div id="organizationSettingsPortal"></div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { Icon, LCard, LTabs } from '@last/core-ui/paprika'
import CancelSubscriptionModal from '@last/core-ui/v3/components/CancelSubscriptionModal.vue'

import api from '@/api'
import { useOrganizationsStore } from '@/store/organizations.ts'

import DemoOrganization from './DemoOrganization.vue'
import ImportCSVCatalog from './ImportCSVCatalog.vue'
import ImportGlovoCatalog from './ImportGlovoCatalog.vue'
import ImportKlikinCatalog from './ImportKlikinCatalog.vue'
import ImportUberCatalog from './ImportUberCatalog.vue'
import OrganizationBillingOptions from './OrganizationBillingOptions.vue'
import OrganizationFeatures from './OrganizationFeatures.vue'
import OrganizationName from './OrganizationName.vue'
import OrganizationShopDomains from './OrganizationShopDomains.vue'
import PublicApiUsers from './PublicApiUsers.vue'
import SupportAccess from './SupportAccess.vue'

export default {
  name: 'OrganizationSettings',
  components: {
    LCard,
    LTabs,
    OrganizationFeatures,
    OrganizationName,
    Icon,
    ImportUberCatalog,
    ImportGlovoCatalog,
    ImportKlikinCatalog,
    OrganizationShopDomains,
    OrganizationBillingOptions,
    ImportCSVCatalog,
    PublicApiUsers,
    SupportAccess,
    DemoOrganization,
    CancelSubscriptionModal
  },
  data() {
    return {
      cancelingSubscription: false,
      showDeleteOrganization: false,
      selectedTabId: null
    }
  },
  mounted() {
    this.selectedTabId = this.tabs[0].id
  },
  methods: {
    ...mapActions(useOrganizationsStore, [
      'selectOrganization',
      'selectLocation',
      'refreshOrganizations'
    ]),
    async deleteOrganization({ reason, comment, endOfTerm }) {
      this.cancelingSubscription = false
      try {
        await api.put(
          `/support/organization/${this.selectedOrganization.id}/delete`,
          {
            reason,
            comment,
            endOfTerm
          }
        )
        await this.refreshOrganizations()
        this.selectOrganization(this.organizations[0])
        this.selectLocation({
          name: this.$t('side-menu.global-settings'),
          id: -1
        })
        this.$router.push({
          name: 'global',
          params: { organizationId: this.organizations[0].id }
        })

        this.$lnotification.create({
          title: this.$t('global-settings.delete-organization-success')
        })
        this.$emit('close')
      } catch (err) {
        if (err.response) {
          this.$lnotification.create({
            title: `${this.$t(
              'global-settings.delete-organization-success'
            )}: ${err.response.request.response}`,
            icon: 'close',
            iconColor: 'red'
          })
        }
      }
    }
  },
  computed: {
    ...mapState(useOrganizationsStore, [
      'selectedOrganization',
      'organizations',
      'selectedLocation'
    ]),
    tabs() {
      return [
        {
          name: this.$t('import-external-catalog.uber'),
          id: 'importUberCatalog'
        },
        {
          name: this.$t('import-external-catalog.glovo'),
          id: 'importGlovoCatalog'
        },
        {
          name: this.$t('import-catalog.csv'),
          id: 'importCSVCatalog'
        },
        {
          name: 'Import Klikin catalog',
          id: 'importKlikinCatalog'
        }
      ]
    }
  }
}
</script>

<style scoped></style>
