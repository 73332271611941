type Parser = (input: string) => string

export default class StringParser {
  static removeSpecialChars(input: string): string {
    // List of special chars: '[' , '/', ']'
    const specialCharRegex = /([\u005B-\u005D])/g

    return input.replace(specialCharRegex, '').trim()
  }

  static removeEmojis(input: string): string {
    const emojiRegex =
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD00-\uDDFF]|\uD83E[\uDE00-\uDEFF]|[\u2011-\u26FF]|[\u200D]|\uFE0F)/g

    return input.replace(emojiRegex, '').trim()
  }

  static cleanObject(
    obj: Record<string, any>,
    parsers: Parser[]
  ): Record<string, any> {
    const result: Record<string, any> = {}
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'string') {
          result[key] = parsers.reduce((res, parser) => {
            return parser(res)
          }, obj[key])
        } else if (Array.isArray(obj[key])) {
          result[key] = this.cleanArray(obj[key], parsers)
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          result[key] = this.cleanObject(obj[key], parsers)
        } else {
          result[key] = obj[key]
        }
      }
    }
    return result
  }

  static cleanArray(arr: any[], parsers: Parser[]): any[] {
    return arr.map(item => {
      if (typeof item === 'string') {
        return parsers.reduce((res, parser) => {
          return parser(res)
        }, item)
      } else if (Array.isArray(item)) {
        return this.cleanArray(item, parsers)
      } else if (typeof item === 'object' && item !== null) {
        return this.cleanObject(item, parsers)
      } else {
        return item
      }
    })
  }
}
