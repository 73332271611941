<template>
  <div class="w-full">
    <div
      :class="{
        'border-red': wrongValue,
        'hover:border-red': wrongValue,
        'focus-within:border-red': wrongValue,
        'my-2': verticalMargin,
        'bg-white border': theme === 'light',
        'bg-transparent-gray': theme === 'dark',
        'opacity-50': disabled,
        'hover:border-gray-400': !disabled,
        'h-full': inputType === 'textarea'
      }"
      class="w-full text-blue border-gray-300 rounded-small focus-within:border-blue flex items-center"
    >
      <slot>
        <input
          v-if="inputType === 'input'"
          ref="input"
          v-model="computedValue"
          class="appearance-none focus:outline-none h-full w-full px-4 rounded-small leading-tight"
          :class="{
            'py-2 text-sm': small,
            'py-4': big,
            'py-3': !small && !big,
            'bg-transparent text-white': theme === 'dark'
          }"
          v-bind="$attrs"
          :placeholder="placeholder"
          :disabled="disabled"
          :maxlength="maxLength"
          @keyup.enter="$emit('enter')"
        />
        <textarea
          v-else-if="inputType === 'textarea'"
          ref="input"
          v-model="computedValue"
          class="appearance-none focus:outline-none h-full w-full px-4 rounded-small leading-tight"
          :class="{
            'py-2 text-sm': small,
            'py-4': big,
            'py-3': !small && !big,
            'bg-transparent text-white': theme === 'dark'
          }"
          v-bind="$attrs"
          :placeholder="placeholder"
          :disabled="disabled"
          :maxlength="maxLength"
        />
        <slot name="icons">
          <div class="h-full rounded-small flex items-center">
            <icon v-if="icon" :name="icon" class="mr-4" :class="iconClass" />
          </div>
        </slot>
      </slot>
    </div>
    <div v-if="wrongValue && errorMessage" class="text-xs text-red ml-4">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { StringParser } from '@last/core'

import Icon from './Icon.vue'

export default {
  name: 'LInput',
  components: {
    Icon
  },
  props: {
    icon: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    iconClass: {
      type: String,
      default: 'text-red'
    },
    modelValue: {
      type: [String, Number],
      default: null
    },
    small: Boolean,
    wrongValue: {
      type: Boolean,
      default: false
    },
    verticalMargin: {
      type: Boolean,
      default: true
    },
    big: Boolean,
    isCurrency: {
      type: Boolean,
      default: false
    },
    currencyDecimals: {
      type: Number,
      default: 2
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light'
    },
    inputType: {
      type: String,
      default: 'input'
    },
    errorMessage: {
      type: String,
      default: null
    },
    maxLength: {
      type: Number,
      default: 255
    },
    noEmojis: {
      type: Boolean,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:model-value', 'enter'],
  computed: {
    computedValue: {
      get() {
        if (this.isCurrency) {
          if (
            this.allowEmpty &&
            (this.modelValue == null || this.modelValue == '')
          ) {
            return ''
          }
          return this.modelValue / Math.pow(10, this.currencyDecimals)
        }
        if (this.noEmojis && this.modelValue) {
          return StringParser.removeEmojis(this.modelValue)
        }
        return this.modelValue
      },
      set(value) {
        if (this.isCurrency) {
          if (this.allowEmpty && (value == null || value == '')) {
            this.$emit('update:model-value', null)
            return
          }
          const roundedValue = Math.round(
            String(value).replace(',', '.') *
              Math.pow(10, this.currencyDecimals)
          )
          this.$emit(
            'update:model-value',
            isNaN(roundedValue) ? null : roundedValue
          )
        } else {
          if (this.noEmojis) {
            value = StringParser.removeEmojis(value)
          }
          this.$emit('update:model-value', value)
        }
      }
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>
