import { defineStore } from 'pinia'

import api from '@/api.js'

interface Integrations {
  [key: string]: any
}

interface IntegrationsState {
  integrations: Integrations
}

export const useIntegrationsStore = defineStore('integrations', {
  state: (): IntegrationsState => ({
    integrations: {}
  }),
  actions: {
    async refreshIntegrations(): Promise<void> {
      const response = await api.get('/support/integrations')
      this.integrations = response.data
    }
  }
})
