<template>
  <div
    v-click-outside="() => (open = false)"
    class="w-full relative text-blue"
    :class="{ 'opacity-50': disabled }"
  >
    <div
      class="w-full rounded-small leading-tight focus-within:border-lblue-500 flex items-center pr-6"
      :class="{
        'bg-gray-100': theme === 'light',
        'border border-white': theme === 'transparent',
        'bg-transparent-gray': theme === 'dark',
        'border border-red': redBorder,
        'my-2': withMargin,
        'cursor-pointer': !disabled
      }"
      @click="handleOpen"
    >
      <icon v-if="icon" :name="icon" class="ml-4" :class="iconClass" />
      <div
        :class="{
          'py-2 text-sm': small,
          'py-3': !small,
          'text-white': theme === 'dark',
          'w-full': arrowStyle !== 2,
          'pr-2': arrowStyle === 2
        }"
        class="px-4"
      >
        <div
          v-if="addButtonSelected"
          class="text-violet flex flex-row items-center"
        >
          <icon small name="plus" />
          <div class="ml-2">{{ addButtonText }}</div>
        </div>
        <div v-else class="truncate">
          {{ text }}
        </div>
      </div>
      <icon
        v-if="!canRemoveSelection"
        :name="arrowStyle === 2 ? 'arrow-down-2' : 'arrow-down'"
        class="arrow"
        :class="{
          rotated: open,
          'text-gray-400': arrowStyle === 2,
          white: arrowStyle !== 2 && theme === 'transparent',
          'text-accent': arrowStyle !== 2
        }"
      />
      <icon
        v-else-if="canRemoveSelection"
        name="close"
        class="arrow text-violet"
        @click.stop="remove"
      />
    </div>
    <transition name="fade">
      <div
        v-if="open"
        class="rounded shadow-md py-2 options absolute left-0 right-0 z-20 text-blue scrollable"
        :class="{
          'bottom-0 mb-12 top': position === 'top' || shouldGoTop,
          bottom: position === 'bottom',
          'bg-white border border-gray-200': theme === 'light',
          'border border-gray-300 w-5/6 ml-5': arrowStyle === 2,
          'bg-blue text-white': theme === 'dark'
        }"
      >
        <l-input
          v-if="queryInput"
          v-model="query"
          class="input-class"
          :placeholder="placeholder"
          icon="search"
          icon-class="text-gray-400"
          small
        />
        <div class="px-4 py-3 cursor-pointer text-sm text-blue">
          <l-checkbox
            v-if="selectAll"
            class="px-4 py-3 mr-2 text-gray-700 text-base rounded-small"
            :class="{ 'hover:bg-gray-200': theme === 'light' }"
            small
            :model-value="allSelected"
            :half="!!(selected.length && !allSelected)"
            :label="$t('core.select-all')"
            @update:model-value="selectAllOptions"
          />
          <div
            v-for="option in filteredOptions"
            :id="option[optionValue]"
            :key="key(option)"
          >
            <div
              class="px-4 py-3 rounded-small"
              :class="{
                'hover:bg-gray-200': theme === 'light',
                'text-white hover:bg-transparent-gray': theme === 'dark'
              }"
              @click="select(option)"
            >
              <l-checkbox
                v-if="multiselect"
                small
                class="mr-2"
                :class="{
                  'text-base text-gray-700': hasChildren(option)
                }"
                :theme="theme"
                :model-value="
                  isSelected(option) > 0 ||
                  (hasSelectedChildren(option) &&
                    !hasUnselectedChildren(option))
                "
                :label="option[optionLabel]"
                :half="
                  isSelected(option) == 2 ||
                  (hasSelectedChildren(option) && hasUnselectedChildren(option))
                "
                @update:model-value="select(option)"
              />
              <div v-else>{{ option[optionLabel] }}</div>
            </div>
            <div v-if="hasChildren(option)">
              <div
                v-for="nestedOption in option.children"
                :key="key(nestedOption)"
              >
                <div
                  class="pr-4 py-2 pl-8 rounded-small"
                  :class="{
                    'hover:bg-gray-200': theme === 'light'
                  }"
                  @click="select(nestedOption)"
                >
                  <l-checkbox
                    v-if="multiselect"
                    small
                    :model-value="isSelected(nestedOption) > 0"
                    :label="nestedOption[optionLabel]"
                    :half="isSelected(nestedOption) == 2"
                    @update:model-value="select(nestedOption)"
                  />
                  <div v-else>{{ nestedOption[optionLabel] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="addButton"
          class="px-4 py-3 text-violet flex flex-row items-center"
          @click="
            () => {
              addButtonSelected = true
              open = false
              $emit('addButtonSelected')
            }
          "
        >
          <icon small name="plus" />
          <div class="ml-2">{{ addButtonText }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Icon from './Icon.vue'
import LInput from '@last/core-ui/v3/components/LInput.vue'
import LCheckbox from '@last/core-ui/v3/components/LCheckbox.vue'
import { toRaw } from 'vue'
const HALF_SELECTED = 2
const FULL_SELECTED = 1

export default {
  name: 'LSelect',
  components: {
    Icon,
    LInput,
    LCheckbox
  },
  props: {
    modelValue: {
      type: [String, Number, Boolean, Array, Object],
      default: undefined
    },
    defaultValue: {
      type: [String, Number, Boolean, Array, Object],
      default: undefined
    },
    options: {
      type: [Array, Object],
      default: () => []
    },
    optionLabel: {
      type: String,
      default: 'label'
    },
    optionValue: {
      type: String,
      default: 'value'
    },
    iconClass: {
      type: String,
      default: 'text-green'
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    position: {
      type: String,
      default: 'bottom'
    },
    icon: {
      type: String,
      default: null
    },
    small: Boolean,
    theme: {
      type: String,
      default: 'light'
    },
    allowRemove: {
      type: Boolean,
      default: false
    },
    withMargin: {
      type: Boolean,
      default: true
    },
    redBorder: {
      type: Boolean,
      default: false
    },
    arrowStyle: {
      type: Number,
      default: 1
    },
    queryInput: {
      type: Boolean,
      default: false
    },
    switched: {
      type: Boolean,
      default: false
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    supportHalfValue: {
      type: Boolean,
      default: false
    },
    addButton: {
      type: Boolean,
      default: false
    },
    addButtonText: {
      type: String,
      default: null
    }
  },
  emits: ['addButtonSelected', 'update:model-value'],
  data() {
    return {
      open: false,
      selected: [],
      query: '',
      addButtonSelected: false
    }
  },
  computed: {
    canRemoveSelection() {
      return this.allowRemove && this.selected.length > 0
    },
    filteredOptions() {
      if (!this.queryInput) {
        return this.optionArray
      }
      return this.optionArray.filter(option =>
        option[this.optionLabel]
          .toUpperCase()
          .includes(this.query.toUpperCase())
      )
    },
    shouldGoTop() {
      let rect = this.$el.getBoundingClientRect()
      return window.innerHeight - rect.bottom < 300 && rect.top >= 300
    },
    text() {
      if (this.supportHalfValue) {
        return (
          this.selected
            .map(selected => {
              let key = selected[this.optionValue]
              let option = this.optionArray.find(
                option => option[this.optionValue] === key
              )
              return option[this.optionLabel]
            })
            .join(', ') || this.placeholder
        )
      }
      let customText = this.selected
        .filter(option => option[this.optionValue] === -1)
        .shift()?.[this.optionLabel]
      const text = this.optionArray.reduce((acc, option) => {
        if (this.hasChildren(option)) {
          if (this.hasSelectedChildren(option)) {
            if (acc) acc += ', '
            let childrenNames
            if (this.hasUnselectedChildren(option)) {
              childrenNames = this.selectedChildren(option)
                .map(child => child[this.optionLabel])
                .join(', ')
            } else {
              childrenNames = this.$t('core.all')
            }
            return acc + `${option[this.optionLabel]}: (${childrenNames})`
          }
        }
        if (this.isSelected(option)) {
          if (acc) acc += ', '
          return acc + option[this.optionLabel]
        }
        return acc
      }, '')
      return customText || text || this.placeholder
    },
    selectedValues() {
      return this.selected.map(
        selectedOption => selectedOption[this.optionValue]
      )
    },
    nOptions() {
      return this.optionArray.reduce((acc, option) => {
        if (option.children) {
          return acc + option.children.length
        } else {
          return acc + 1
        }
      }, 0)
    },
    allSelected() {
      return this.nOptions === this.selected.length
    },
    optionArray() {
      if (!Array.isArray(this.options)) {
        if (typeof this.options === 'object') {
          return Object.values(this.options)
        } else {
          throw new Error('Options prop must be an array or an object')
        }
      }
      return this.options
    }
  },
  watch: {
    options() {
      this.selected = this.defaultSelect()
    },
    modelValue() {
      this.selected = this.defaultSelect()
    },
    switched() {
      this.open = !this.open
    },
    disabled(value) {
      if (value && this.open) this.open = false
    }
  },
  mounted() {
    this.selected = this.defaultSelect()
  },
  methods: {
    select(option) {
      const selectedValue = option[this.optionValue]
      if (!this.multiselect) {
        this.$emit('update:model-value', selectedValue)
        this.addButtonSelected = false
        this.open = false
        return
      }
      if (this.hasChildren(option)) {
        this.$emit('update:model-value', this.selectNested(option))
        return
      }

      const alreadySelectedValues = this.selectedValues.filter(
        value => value != selectedValue
      )

      const optionWasAlreadySelected =
        this.selectedValues.includes(selectedValue)

      let newSelected = [...alreadySelectedValues]
      if (!this.supportHalfValue) {
        if (!optionWasAlreadySelected) {
          newSelected = [...alreadySelectedValues, selectedValue]
        }
      } else {
        if (
          !optionWasAlreadySelected ||
          this.selected.find(s => s.id == option.id)?.status == HALF_SELECTED
        ) {
          newSelected = [
            ...this.selected,
            {
              ...option,
              status: FULL_SELECTED
            }
          ]
        } else {
          newSelected = this.selected.filter(s => s.id != option.id)
        }
      }
      this.$emit('update:model-value', newSelected)
    },
    remove() {
      this.selected = []
      this.$emit('update:model-value', null)
    },
    selectAllOptions() {
      if (this.selected.length) {
        this.$emit('update:model-value', [])
        return
      }
      this.selected = this.optionArray.flatMap(option => {
        if (this.hasChildren(option)) {
          return this.childrenValues(option)
        }
        return [option[this.optionValue]]
      })
      this.$emit('update:model-value', this.selected)
    },
    defaultSelect() {
      let options = this.optionArray
      if (!this.multiselect) {
        return [
          options.find(option => {
            if (this.modelValue?.isSame) {
              return option[this.optionValue].isSame(this.modelValue)
            } else {
              return option[this.optionValue] === this.modelValue
            }
          })
        ].filter(option => !!option)
      }

      const initialValue = this.modelValue || []
      if (this.supportHalfValue) {
        return initialValue.map(value => {
          return {
            [this.optionValue]: value[this.optionValue],
            status: value.status
          }
        })
      }
      const optionsByValue = options.reduce((res, option) => {
        if (this.hasChildren(option)) {
          option.children.forEach(child => {
            res[child[this.optionValue]] = child
          })
        } else {
          res[option[this.optionValue]] = option
        }
        return res
      }, {})
      return initialValue
        .map(value => optionsByValue[value])
        .filter(value => value !== undefined)
    },
    isSelected(option) {
      if (this.supportHalfValue) {
        const selectedOption = this.selected.find(
          selected => selected[this.optionValue] === option[this.optionValue]
        )
        return selectedOption?.status
      }
      return !!toRaw(this.selected).find(
        selected => selected[this.optionValue] === option[this.optionValue]
      )
    },
    handleOpen() {
      if (!this.disabled) {
        this.open = !this.open
      }
    },
    key(option) {
      let value = option[this.optionValue]
      if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value)
      } else {
        return value
      }
    },
    hasChildren(option) {
      return !!option.children
    },
    selectNested(option) {
      const childrenValues = this.childrenValues(option)
      if (this.hasSelectedChildren(option)) {
        return this.selectedValues.filter(selectedOption => {
          return !childrenValues.includes(selectedOption)
        })
      } else {
        return [...this.selectedValues, ...childrenValues]
      }
    },
    selectedChildren(option) {
      return option.children?.filter(child => this.isSelected(child))
    },
    childrenValues(option) {
      return option.children?.map(child => child[this.optionValue])
    },
    hasSelectedChildren(option) {
      return option.children?.some(child => this.isSelected(child))
    },
    hasUnselectedChildren(option) {
      return option.children?.some(child => !this.isSelected(child))
    }
  }
}
</script>

<style scoped>
.arrow {
  transition: all 0.5s;
}

.rotated {
  transform: rotate(180deg);
}

.options {
  margin-top: -6px;
}

.options:after,
.options:before {
  bottom: 100%;
  right: 15px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.options:after {
  border-color: rgba(255, 255, 255, 0);
  border-width: 8px;
  right: 16px;
  background-clip: padding-box;
}

.options:before {
  border-color: rgba(184, 184, 184, 0);
  border-width: 9px;
  background-clip: padding-box;
}

.options.top:after,
.options.top:before {
  top: 100%;
  border-top-color: #e2e8f0;
}

.options.bottom:after,
.options.bottom:before {
  bottom: 100%;
  border-bottom-color: #e2e8f0;
}

.options.top:after {
  border-top-color: #ffffff;
}

.options.top:before {
  border-top-color: #e2e8f0;
}

.options.bottom:after {
  border-bottom-color: #ffffff;
}

.options.bottom:before {
  border-bottom-color: #e2e8f0;
}

.scrollable {
  max-height: 300px;
  overflow-y: scroll;
}

.input-class {
  @apply w-10/12 m-auto my-2;
}

.white {
  color: white;
}

.small-checkbox {
  @apply w-4 h-4;
}
</style>
