<template>
  <div class="px-5 relative flex h-full">
    <div class="flex-1 overflow-y-scroll h-full">
      <div class="flex justify-between items-center">
        <div
          v-if="selectedLocation && selectedOrganization"
          class="items-baseline uppercase text-blue font-bold text-xl font-title px-5 pb-5 pt-8"
        >
          <div class="mr-3 text-gray-400">
            {{ selectedOrganization.name }}
          </div>
          <location-name :location="selectedLocation" />
        </div>
      </div>
      <support-access />
      <open-tab />
      <delete-tab />
      <delete-tabs-by-date />
      <location-customer-id
        v-if="locationConfig"
        v-model="locationConfig.customerId"
      />
      <subscriptions
        :location-subscription="locationConfig.locationSubscription"
        :zoho-subscriptions="locationConfig.zohoSubscriptions"
        @updated="refreshLocationConfig()"
        @created="refreshLocationConfig()"
      />
      <integration-settings
        v-if="Object.keys(integrations).length"
        :current-integrations="integrations"
        @refresh-integrations="refreshIntegrations"
      />
      <shipment-provider-settings
        :selected="shipmentProviders.selected"
        :providers="shipmentProviders.providers"
        @update="refreshShipmentProviders"
      />
      <devices-settings :devices="devices" @refresh-devices="refreshDevices" />
      <last-payments :devices="devices" @refresh-devices="refreshDevices" />
      <import-areas />
    </div>
    <div id="locationSettingsPortal"></div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import api from '@/api.js'
import { useOrganizationsStore } from '@/store/organizations'

import DeleteTab from './DeleteTab.vue'
import DeleteTabsByDate from './DeleteTabsByDate.vue'
import DevicesSettings from './DevicesSettings.vue'
import ImportAreas from './ImportAreas.vue'
import IntegrationSettings from './IntegrationSettings.vue'
import LastPayments from './LastPayments.vue'
import LocationCustomerId from './LocationCustomerId.vue'
import LocationName from './LocationName.vue'
import OpenTab from './OpenTab.vue'
import ShipmentProviderSettings from './ShipmentProviderSettings.vue'
import Subscriptions from './Subscriptions.vue'
import SupportAccess from './SupportAccess.vue'

export default {
  name: 'LocationSettings',
  components: {
    IntegrationSettings,
    DevicesSettings,
    ShipmentProviderSettings,
    LocationCustomerId,
    OpenTab,
    DeleteTab,
    DeleteTabsByDate,
    LocationName,
    SupportAccess,
    Subscriptions,
    LastPayments,
    ImportAreas
  },
  data() {
    return {
      integrations: {},
      devices: null,
      locationConfig: {},
      shipmentProviders: {
        selected: null,
        providers: []
      },
      posEnabled: true,
      showDisablePopup: false
    }
  },
  mounted() {
    this.refreshData()
  },
  computed: {
    ...mapState(useOrganizationsStore, [
      'selectedOrganization',
      'selectedLocation'
    ])
  },
  methods: {
    refreshData() {
      if (this.selectedLocation && this.selectedLocation.id !== -1) {
        this.refreshIntegrations()
        this.refreshLocationConfig()
        this.refreshDevices()
        this.refreshShipmentProviders()
      }
    },
    async refreshIntegrations() {
      const result = await api.get(
        `/support/location/${this.selectedLocation.id}/integrations`
      )
      this.integrations = result.data
    },
    async refreshLocationConfig() {
      const result = await api.get(
        `/support/location/${this.selectedLocation.id}/config`
      )
      this.locationConfig = result.data
    },
    async refreshDevices() {
      const result = await api.get(
        `/support/location/${this.selectedLocation.id}/devices`
      )
      this.devices = result.data
    },
    async refreshShipmentProviders() {
      const result = await api.get(
        `/support/organization/${this.selectedOrganization.id}/location/${this.selectedLocation.id}/shipment`
      )

      this.shipmentProviders = result.data
    }
  },
  watch: {
    selectedLocation() {
      this.refreshData()
    }
  }
}
</script>

<style scoped>
.arrow {
  transition: all 0.3s;
}

.rotated {
  transform: rotate(90deg);
}

.separator {
  height: 1px;
}
</style>
