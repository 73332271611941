<template>
  <label>
    <template v-if="!dragDrop">
      <slot />
    </template>

    <div
      v-else
      class="w-full h-full flex items-center justify-center overflow-hidden border cursor-pointer text-gray-400"
      :class="{
        'border-violet box-border': dragDropFocus,
        'border-dashed': !dragDropFocus,
        'bg-transparent-gray border-gray-400': theme === 'dark',
        'bg-transparent-light-gray border-gray-300': theme === 'light',
        'cursor-pointer': !disabled,
        'pointer-events-none': disabled,
        'rounded-full': circle,
        'rounded-small': !circle
      }"
      @dragover.prevent="updateDragDropFocus(true)"
      @dragleave.prevent="updateDragDropFocus(false)"
      @dragenter.prevent="updateDragDropFocus(true)"
      @drop.prevent="onFileChange"
    >
      <slot>
        <div class="flex flex-col items-center p-2">
          <icon name="upload" class="text-violet" />
          <span v-if="text" v-html="text" class="mt-2"></span>
        </div>
      </slot>
    </div>

    <input
      ref="uploadInput"
      class="hidden"
      type="file"
      v-bind="$attrs"
      :multiple="multiple"
      :accept="accept"
      :disabled="disabled"
      @change="onFileChange"
    />
  </label>
</template>

<script>
import Icon from './Icon.vue'

export default {
  name: 'LUpload',
  inheritAttrs: false,
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [Object, Function, File, Array]
    },
    multiple: Boolean,
    accept: String,
    dragDrop: Boolean,
    theme: {
      type: String,
      default: 'light'
    },
    native: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      newValue: this.modelValue,
      dragDropFocus: false,
      loading: false
    }
  },
  watch: {
    modelValue(modelValue) {
      let inputFiles = this.$refs.uploadInput.files
      this.newValue = modelValue
      if (
        !this.newValue ||
        (Array.isArray(this.newValue) && this.newValue.length === 0) ||
        !inputFiles[0] ||
        (Array.isArray(this.newValue) &&
          !this.newValue.some(function (a) {
            return a.name === inputFiles[0].name
          }))
      ) {
        this.$refs.uploadInput.value = null
      }
    }
  },
  methods: {
    onFileChange(event) {
      if (this.disabled || this.loading) return
      if (this.dragDrop) {
        this.updateDragDropFocus(false)
      }
      const value = event.target.files || event.dataTransfer.files
      if (value.length === 0) {
        if (!this.newValue) {
          return
        }
        if (this.native) {
          this.newValue = null
        }
      } else if (!this.multiple) {
        if (this.dragDrop && value.length !== 1) return
        else {
          const file = value[0]
          if (this.checkType(file)) {
            this.newValue = file
          } else if (this.newValue) {
            this.newValue = null
          } else {
            return
          }
        }
      } else {
        let newValues = false
        if (this.native || !this.newValue) {
          this.newValue = []
          newValues = true
        }
        for (let i = 0; i < value.length; i++) {
          const file = value[i]
          if (this.checkType(file)) {
            this.newValue.push(file)
            newValues = true
          }
        }
        if (!newValues) {
          return
        }
      }
      this.$emit('update:modelValue', this.newValue)
    },
    updateDragDropFocus(focus) {
      if (!this.disabled && !this.loading) {
        this.dragDropFocus = focus
      }
    },
    checkType(file) {
      if (!this.accept) return true
      const types = this.accept.split(',')
      if (types.length === 0) return true
      let valid = false
      for (let i = 0; i < types.length && !valid; i++) {
        const type = types[i].trim()
        if (type) {
          if (type.substring(0, 1) === '.') {
            // check extension
            const extIndex = file.name.lastIndexOf('.')
            const extension = extIndex >= 0 ? file.name.substring(extIndex) : ''
            if (extension.toLowerCase() === type.toLowerCase()) {
              valid = true
            }
          } else {
            // check mime type
            if (file.type.match(type)) {
              valid = true
            }
          }
        }
      }
      return valid
    }
  },
  components: {
    Icon
  }
}
</script>
