<template>
  <div>
    <l-card :title="$t('shipment-providers.title')" overflow-visible>
      <l-field
        :label="$t('shipment-providers.selection-label')"
        class="pb-10 max-w-xs"
      >
        <l-select
          :placeholder="$t('shipment-providers.selection-placeholder')"
          v-model="internalSelected"
          :options="filteredProviders"
          :disabled="filteredProviders.length === 0"
          allow-remove
        />
      </l-field>
    </l-card>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { LCard, LField, LSelect } from '@last/core-ui/paprika'

import api from '@/api.js'
import { useOrganizationsStore } from '@/store/organizations.ts'

export default {
  name: 'ShipmentProviderSettings',
  components: {
    LCard,
    LSelect,
    LField
  },
  props: {
    selected: {
      type: String,
      default: ''
    },
    providers: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    async updateSelected(newSelected) {
      await api.put(`/support/location/${this.selectedLocation.id}/shipment`, {
        selected: newSelected
      })
      this.$emit('update')
    }
  },
  computed: {
    ...mapState(useOrganizationsStore, ['selectedLocation']),
    filteredProviders() {
      return this.providers
        .filter(provider => Object.keys(provider).length > 1)
        .map(provider => ({
          label: provider.name,
          value: provider.name
        }))
    },
    internalSelected: {
      get() {
        return this.selected
      },
      async set(newSelected) {
        if (this.selected === newSelected) return
        await this.updateSelected(newSelected)
      }
    }
  }
}
</script>

<style scoped>
::v-deep .separator {
  height: 1px;
}

::v-deep .provider-row:last-child .separator {
  height: 0;
}
</style>
