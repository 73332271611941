<template>
  <l-card :title="$t('organization-features.title')">
    <div class="text-blue" v-if="organizationConfig">
      <div>
        <div v-for="(feature, index) in features" :key="feature">
          <div class="flex flex-row items-center justify-between mt-5">
            <div>
              {{ $t(`organization-features.${toKebabCase(feature)}`) }}
            </div>
            <div>
              <l-switch
                v-model="organizationConfig[feature]"
                @update:model-value="() => updateOrganizationConfig(feature)"
              />
            </div>
          </div>
          <div
            v-if="index !== features.length - 1"
            class="separator bg-gray-200 mt-5 -mx-3"
          />
        </div>
      </div>
    </div>
  </l-card>
</template>

<script>
import { mapState } from 'pinia'

import { LCard, LSwitch } from '@last/core-ui/paprika'

import api from '@/api.js'
import { useOrganizationsStore } from '@/store/organizations.ts'

export default {
  name: 'OrganizationFeatures',
  components: {
    LCard,
    LSwitch
  },
  data() {
    return {
      organizationConfig: null
    }
  },
  async mounted() {
    await this.refreshOrganizationConfig()
  },
  methods: {
    async refreshOrganizationConfig() {
      if (this.selectedOrganization) {
        const result = await api.get(
          `/support/organization/${this.selectedOrganization.id}/config`
        )
        this.organizationConfig = result.data
      }
    },
    toKebabCase(str) {
      return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    },
    async updateOrganizationConfig(feature) {
      await api.put(
        `/support/organization/${this.selectedOrganization.id}/config/update`,
        { [feature]: this.organizationConfig[feature] }
      )
    }
  },
  computed: {
    ...mapState(useOrganizationsStore, ['selectedOrganization']),
    features() {
      return Object.keys(this.organizationConfig).filter(
        config => config !== 'organizationId'
      )
    }
  },
  watch: {
    async selectedOrganization() {
      await this.refreshOrganizationConfig()
    }
  }
}
</script>

<style scoped>
.separator {
  height: 1px;
}
</style>
