<template>
  <div>
    <l-card
      :title="$t('public-api.title')"
      primary="plus"
      @primary="creatingOrEditing = true"
    >
      <div class="flex flex-row items-center text-gray-400 text-xs mb-3">
        <div class="w-1/6">
          {{ $t('public-api.name') }}
        </div>
        <div class="w-1/6">
          {{ $t('public-api.source') }}
        </div>
        <div class="w-1/12">
          {{ $t('public-api.is-order-creator') }}
        </div>
        <div class="w-1/6">
          {{ $t('public-api.is-delivery') }}
        </div>
        <div class="w-1/6">
          {{ $t('public-api.webhook') }}
        </div>
        <div class="flex-1">
          {{ $t('public-api.events') }}
        </div>
        <div class="w-1/12" />
      </div>
      <div
        v-for="user in mappedApiUsers"
        :key="user.id"
        class="text-gray-400 text-sm"
      >
        <div class="separator bg-gray-200 -mx-8" />
        <div class="flex flex-row py-3">
          <div class="w-1/6 text-blue">
            {{ user.name }}
          </div>
          <div class="w-1/6">
            {{ user.source }}
          </div>
          <div class="w-1/12">
            {{ user.isOrderCreator }}
          </div>
          <div class="w-1/6">
            {{ !!user.delivery }}
          </div>
          <div class="w-1/6">
            <div class="w-10/12 break-words">
              {{ user.webhookEndpoint }}
            </div>
          </div>
          <div class="flex-1">
            {{ user.eventsConcatenated }}
          </div>
          <div class="w-1/12 flex flex-row justify-end">
            <icon
              name="pen"
              class="text-blue ml-2 cursor-pointer"
              @click="editUser(user)"
            />
            <icon
              name="trash"
              class="text-blue ml-2 cursor-pointer"
              @click="removeUser(user)"
            />
          </div>
        </div>
        <div class="flex flex-row bg-gray-100 py-3 -mx-8">
          <div class="ml-8">Token:</div>
          <div class="text-blue ml-3">
            {{ user.token }}
          </div>
        </div>
      </div>
    </l-card>
    <teleport to="#globalSettingsPortal">
      <side-panel :open="creatingOrEditing">
        <public-api-user-editor
          @close="closeEditor"
          @save-user="saveUser"
          :user="editingUser"
          @update-device="device => updateDevice(device)"
        />
      </side-panel>
    </teleport>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { Icon, LCard } from '@last/core-ui/paprika'
import SidePanel from '@last/core-ui/v3/components/SidePanel.vue'

import api from '@/api.js'
import { useOrganizationsStore } from '@/store/organizations.ts'

import PublicApiUserEditor from './PublicApiUserEditor.vue'

export default {
  name: 'PublicApiUsers',
  data() {
    return {
      apiUsers: null,
      creatingOrEditing: false,
      editingUser: null
    }
  },
  components: {
    LCard,
    Icon,
    PublicApiUserEditor,
    SidePanel
  },
  async mounted() {
    await this.refreshApiUsers()
  },
  methods: {
    async saveUser(user) {
      try {
        await api.post(
          `/support/organization/${this.selectedOrganization.id}/public-api-users`,
          user
        )
        await this.refreshApiUsers()
      } catch {
        this.$lnotification.create({
          title: this.$t('public-api.error'),
          icon: 'close',
          iconColor: 'red'
        })
      }
      this.creatingOrEditing = false
      this.editingUser = null
    },
    async removeUser(user) {
      this.$ldialog({
        title: this.$t('public-api.remove-message'),
        mainLabel: this.$t('ctas.remove'),
        icon: 'trash',
        onConfirm: async () => {
          await api.delete(
            `/support/organization/${this.selectedOrganization.id}/public-api-users/${user.id}`
          )
          await this.refreshApiUsers()
        }
      })
    },
    editUser(user) {
      this.creatingOrEditing = true
      this.editingUser = user
    },
    closeEditor() {
      this.creatingOrEditing = false
      this.editingUser = null
    },
    async refreshApiUsers() {
      if (this.selectedOrganization) {
        const result = await api.get(
          `/support/organization/${this.selectedOrganization.id}/public-api-users`
        )
        this.apiUsers = result.data
      }
    }
  },
  computed: {
    ...mapState(useOrganizationsStore, ['selectedOrganization']),
    mappedApiUsers() {
      if (!this.apiUsers) return null
      return this.apiUsers.map(user => {
        return {
          ...user,
          eventsConcatenated: user.webhooks.map(w => w.eventType).join(', ')
        }
      })
    }
  },
  watch: {
    async selectedOrganization() {
      await this.refreshApiUsers()
    }
  }
}
</script>

<style scoped>
.separator {
  height: 1px;
}
</style>
