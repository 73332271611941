<template>
  <side-panel-view @close="$emit('close')">
    <div class="text-xl font-bold uppercase pb-2">
      {{ version.name }}
    </div>

    <div class="pt-10 px-0">
      <div
        v-for="platform in platforms"
        :key="platform"
        class="flex w-full mt-3 items-center"
      >
        <div class="flex-1">
          {{ $t(`app-versions.${platform}`) }}
        </div>

        <p v-if="isDisabled(platform)" class="text-red px-4 py-2 mx-2">
          {{ $t('app-versions.disabled') }}
        </p>
        <p v-if="isActive(platform)" class="text-green px-4 py-2 mx-2">
          {{ $t('app-versions.active') }}
        </p>
        <p v-if="isUnavailable(platform)" class="text-gray-200 px-4 py-2 mx-2">
          {{ $t('app-versions.unavailable') }}
        </p>

        <button
          v-if="isAvailable(platform)"
          class="text-sm text-white text-center font-medium px-4 py-2 mx-2 bg-green hover:bg-green-b rounded-lg cursor-pointer"
          @click="$emit('activate', platform)"
        >
          {{ $t('app-versions.activate') }}
        </button>

        <button
          v-if="isAvailable(platform)"
          class="text-sm text-white text-center font-medium px-4 py-2 mx-2 bg-red hover:bg-red-b rounded-lg cursor-pointer"
          @click="$emit('disable', platform)"
        >
          {{ $t('app-versions.disable') }}
        </button>
      </div>
    </div>
  </side-panel-view>
</template>

<script>
import SidePanelView from '@last/core-ui/v3/components/SidePanelView.vue'

export default {
  name: 'AppVersionEditor',
  data() {
    return {
      platforms: ['windows', 'win32', 'bundle']
    }
  },
  props: {
    version: {
      type: Object,
      required: true
    }
  },
  methods: {
    isActive(p) {
      return this.version[p] === 'active'
    },
    isAvailable(p) {
      return this.version[p] === 'available'
    },
    isDisabled(p) {
      return this.version[p] === 'disabled'
    },
    isUnavailable(p) {
      return this.version[p] == null
    }
  },
  components: {
    SidePanelView
  }
}
</script>
