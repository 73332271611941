<template>
  <l-card :title="$t('tabs.delete-tab')">
    <div class="flex flex-row justify-between items-center">
      <div class="flex-1 mr-10">
        <l-input v-model="tabId" :vertical-margin="false" />
      </div>
      <div
        class="border border-violet text-violet rounded-lg w-1/4 text-center py-2 cursor-pointer flex justify-center"
        :class="{ 'opacity-50': tabId === '' }"
        @click="deleteTab"
      >
        {{ $t('tabs.delete-tab') }}
      </div>
    </div>
  </l-card>
</template>

<script>
import { mapState } from 'pinia'

import { LCard, LInput } from '@last/core-ui/paprika'

import api from '@/api.js'
import { useOrganizationsStore } from '@/store/organizations.ts'

export default {
  name: 'DeleteTab',
  components: {
    LCard,
    LInput
  },
  data() {
    return {
      tabId: ''
    }
  },
  methods: {
    async deleteTab() {
      try {
        await api.delete(
          `/support/location/${this.selectedLocation.id}/tabs/${this.tabId}/delete`
        )
        this.$lnotification.create({
          title: this.$t('tabs.delete-tab-success'),
          icon: 'success',
          iconColor: 'green'
        })
        this.tabId = ''
      } catch (e) {
        this.$lnotification.create({
          title: this.$t('tabs.delete-tab-failed'),
          subtitle: e.response.data,
          icon: 'close',
          iconColor: 'red'
        })
      }
    }
  },
  computed: {
    ...mapState(useOrganizationsStore, ['selectedLocation'])
  }
}
</script>

<style scoped>
.fit-content {
  height: fit-content;
}
</style>
