import './style.scss'
import './devCode.js'

import App from './App.vue'
import router from './router.js'

import './filters'

import { createPinia } from 'pinia'
import vClickOutside from 'v-click-outside'
import { createApp } from 'vue'

import { LDialogPlugin, LNotificationPlugin } from '@last/core-ui/paprika'

import { createFilters } from './filters'
import i18n from './i18n'

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)

app.use(vClickOutside)
app.use(LNotificationPlugin)
app.use(LDialogPlugin)
app.use(router)
app.use(i18n)

const filters = createFilters(pinia)
app.config.globalProperties.$filters = filters
app.provide('filters', filters)

app.mount('#app')
