import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n } from 'vue-i18n'

export const locales = ['en', 'es', 'ca', 'de']

export default createI18n({
  locale: import.meta.env.VUE_APP_I18N_LOCALE || 'es',
  fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
  globalInjection: true,
  messages,
  legacy: false
})
