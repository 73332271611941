import { defineStore } from 'pinia'
import { computed, ref } from 'vue' // Added imports

import { useRoute, useRouter } from 'vue-router'

import api from '@/api.js'
import i18n from '@/i18n'

interface User {
  id: number
  name: string
  email: string
  permissions: Record<string, boolean>
}

export const useAuthStore = defineStore('auth', () => {
  const accessToken = ref<string | null>(localStorage.getItem('accessToken'))
  const user = ref<User | null>({
    permissions: {}
  })
  const devMode = ref<boolean>(false)

  const isAuthenticated = computed(() => !!accessToken.value)
  const rootUser = computed(() => user.value)
  const permissions = computed(() => user.value?.permissions || {})
  const router = useRouter()
  const route = useRoute()

  const setAccessToken = (token: string) => {
    accessToken.value = token
    localStorage.setItem('accessToken', token)
  }

  const logoutAction = () => {
    accessToken.value = null
    user.value = null
    localStorage.clear()
  }

  const refreshCurrentUserAction = (userData: User) => {
    userData.permissions = userData.permissions
      ? Object.keys(userData.permissions).reduce(
          (acc, permission) => {
            acc[permission] = true
            return acc
          },
          {} as Record<string, boolean>
        )
      : {}
    user.value = userData
  }

  const login = async (loginData: { email: string; password: string }) => {
    const response = await api.post('/support/users/login', loginData)
    setAccessToken(response.data.token)
  }

  const logout = async () => {
    await api.post('/users/logout')
    logoutAction()
  }

  const refreshCurrentUser = async () => {
    try {
      const response = await api.get('/users/me')
      const userData: User = response.data
      window.pagesense = window.pagesense || []
      window.pagesense.push([
        'trackUser',
        { id: userData.id, name: userData.name, email: userData.email }
      ])
      window.pagesense.push([
        'identifyUser',
        `${userData.name} <${userData.email}>`
      ])
      if (route.query.translate === 'true') {
        i18n.locale = ''
        i18n.fallbackLocale = ''
      }
      refreshCurrentUserAction(userData)
    } catch {
      logoutAction()
      router.push({ name: 'login' })
    }
  }

  const setDevModeFlag = (mode: boolean) => {
    devMode.value = mode
  }

  window.addEventListener('storage', (e: StorageEvent) => {
    if (e.key === 'accessToken' && !localStorage.getItem('accessToken')) {
      logout()
    }
  })

  return {
    accessToken,
    user,
    devMode,
    isAuthenticated,
    rootUser,
    permissions,
    login,
    logout,
    refreshCurrentUser,
    setDevMode: setDevModeFlag
  }
})
