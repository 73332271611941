<template>
  <div class="h-screen flex flex-col overflow-hidden relative">
    <top-bar />
    <div class="flex h-full bg-gray-100 overflow-hidden">
      <div class="h-full relative z-20 hidden sm:block">
        <settings-menu v-if="isSettingsRoute" />
        <side-menu v-else />
      </div>
      <div class="layout-content overflow-x-hidden h-full">
        <router-view v-if="isMounted"></router-view>
      </div>
      <div id="locationSettingsPortal" class="relative"></div>
      <div id="globalSettingsPortal" class="relative"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'pinia'
import { useRoute } from 'vue-router'

import SettingsMenu from '@/components/SettingsMenu.vue'
import SideMenu from '@/components/SideMenu.vue'
import { useAuthStore } from '@/store/auth.ts'
import { useIntegrationsStore } from '@/store/integrations.ts'
import { useOrganizationsStore } from '@/store/organizations.ts'

import TopBar from './TopBar.vue'

export default {
  name: 'Support',
  data() {
    return {
      menuExpanded: false,
      isMounted: false
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.refreshCurrentUser()
      this.refreshOrganizations()
      this.refreshIntegrations()
    }
    this.isMounted = true
  },
  methods: {
    ...mapActions(useAuthStore, ['refreshCurrentUser']),
    ...mapActions(useOrganizationsStore, ['refreshOrganizations']),
    ...mapActions(useIntegrationsStore, ['refreshIntegrations'])
  },
  computed: {
    ...mapGetters(useAuthStore, ['isAuthenticated']),
    isSettingsRoute() {
      const route = useRoute()
      return (
        route.path.includes('/settings/') || route.name?.includes('settings')
      )
    }
  },
  components: {
    TopBar,
    SideMenu,
    SettingsMenu
  }
}
</script>

<style>
.layout {
  display: flex;
}

.layout-content {
  flex-grow: 4;
  flex-shrink: 0;
  flex-basis: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.transparent-background {
  background-color: rgba(30, 32, 31, 0.5);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.topbar-margin {
  margin-top: 67px;
}
</style>
