<template>
  <div
    class="rounded-lg bg-transparent-gray text-violet flex justify-center px6 py-2"
    :class="[textStyle, { shake: shakeEnabled }]"
  >
    <div class="flex items-center space-x-1">
      <input
        ref="hours"
        id="hours"
        v-model="hours"
        class="appearance-none w-5 focus:outline-none h-full leading-tight text-right bg-transparent"
        :class="[textStyle]"
        @input="emitData()"
        @focus="$event.target.select()"
      />
      <span :class="[textStyle]">:</span>
      <input
        ref="minutes"
        id="minutes"
        v-model="minutes"
        class="appearance-none w-5 focus:outline-none h-full leading-tight text-left bg-transparent"
        :class="[textStyle]"
        @input="emitData()"
        @focus="$event.target.select()"
      />
    </div>
  </div>
</template>
<script>
import { lastUtils } from '@last/core'
import moment from 'moment'

export default {
  name: 'TimePicker',
  props: {
    modelValue: {
      type: String,
      default: '00:00'
    },
    textStyle: {
      type: String,
      default: 'text-violet'
    }
  },
  data() {
    return {
      shakeEnabled: false,
      hours: '00',
      minutes: '00',
      loaded: false
    }
  },
  created() {
    this.debouncedEmit = lastUtils.debounce(this.emitData, 50)
  },
  async mounted() {
    this.setInputFilter(this.$refs.hours, function (value) {
      return /^\d{0,2}$/.test(value) && (value === '' || parseInt(value) <= 23)
    })
    this.setInputFilter(this.$refs.minutes, function (value) {
      return /^\d{0,2}$/.test(value) && (value === '' || parseInt(value) <= 59)
    })
    this.hours = moment(this.modelValue, 'HH:mm').format('HH')
    this.minutes = moment(this.modelValue, 'HH:mm').format('mm')
    this.$nextTick(() => {
      this.loaded = true
    })
  },
  methods: {
    setInputFilter(textbox, inputFilter) {
      ;[
        'input',
        'keydown',
        'keyup',
        'mousedown',
        'mouseup',
        'select',
        'contextmenu',
        'drop'
      ].forEach(function (event) {
        textbox.addEventListener(event, function () {
          if (inputFilter(this.modelValue)) {
            this.oldValue = this.modelValue
            this.oldSelectionStart = this.selectionStart
            this.oldSelectionEnd = this.selectionEnd
          }
        })
      })
    },
    shake() {
      this.shakeEnabled = true
      setTimeout(() => {
        this.shakeEnabled = false
      }, 500)
    },
    emitData() {
      if (this.loaded && this.hours.length == 2 && this.minutes.length == 2) {
        this.$emit('update:model-value', this.editingDate)
      }
    }
  },
  computed: {
    editingDate() {
      return `${this.hours}:${this.minutes}`
    }
  },
  watch: {
    modelValue(value) {
      this.hours = moment(value, 'HH:mm').format('HH')
      this.minutes = moment(value, 'HH:mm').format('mm')
    },
    hours(value, oldValue) {
      const intValue = parseInt(value)
      if (intValue < 24) {
        this.hours = value
      } else if (isNaN(intValue)) {
        this.hours = ''
      } else {
        this.hours = oldValue
        this.shake()
      }
    },
    minutes(value, oldValue) {
      const intValue = parseInt(value)
      if (intValue < 60) {
        this.minutes = value
      } else if (isNaN(intValue)) {
        this.minutes = ''
      } else {
        this.minutes = oldValue
        this.shake()
      }
    }
  }
}
</script>
<style scoped>
.shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
