<template>
  <div class="h-full overflow-hidden relative" @after-enter="focusSidePanel">
    <transition :name="transitionName">
      <div
        v-if="!showNext"
        ref="sidePanel"
        :tabindex="-1"
        class="h-full overflow-hidden w-full sm:w-[43.75rem] flex flex-col"
        @transitionend="focusSidePanel"
        @keydown.esc.stop="close"
      >
        <div class="absolute top-0 right-0 p-4 cursor-pointer" @click="close">
          <icon name="close" class="text-gray-400" />
        </div>
        <div class="overflow-y-scroll flex-1 px-4 sm:px-12 h-full pt-12 pb-28">
          <slot />
        </div>
        <div class="flex-none py-2 px-12">
          <slot name="footer" />
        </div>
      </div>
      <slot v-else name="next" class="absolute top-0" @close="focusSidePanel" />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { Icon } from '@last/core-ui/paprika'

const props = withDefaults(defineProps<{ showNext: boolean }>(), {
  showNext: false
})
const emit = defineEmits(['close'])

const sidePanel = ref()
function focusSidePanel() {
  if (sidePanel.value) {
    sidePanel.value.focus()
  }
}

const transitionName = computed(() => {
  return props.showNext ? 'movenext' : 'move'
})
const close = () => emit('close')
</script>

<style scoped>
.move-enter-active,
.move-leave-active {
  transition: transform 0.5s;
}
.move-leave-to {
  transform: translateX(100%);
}

.move-enter-from {
  transform: translateX(-100%);
}

.movenext-enter-active,
.movenext-leave-active {
  transition: transform 0.5s;
}
.movenext-leave-to {
  transform: translateX(-100%);
}

.movenext-enter-from {
  transform: translateX(100%);
}
</style>
