<template>
  <div class="px-8 py-12">
    <l-card :title="$t('integrators.title')">
      <div class="mb-6 -mt-5">
        <l-input
          v-model="query"
          class="max-w-lg"
          icon="search"
          icon-class="text-blue"
        />
      </div>
      <div
        class="flex flex-row border-b border-gray-200 text-gray-500 text-sm pb-2"
      >
        <div class="w-3/12">{{ $t('integrators.name') }}</div>
        <div class="w-4/12">{{ $t('integrators.token') }}</div>
        <div class="w-1/12">{{ $t('integrators.creationTime') }}</div>
        <div class="w-3/12">{{ $t('integrators.status') }}</div>
      </div>
      <div
        v-for="(integrator, index) of filteredIntegrators"
        :key="index"
        class="my-4 flex items-center text-blue-600 text-sm"
      >
        <div class="flex items-center w-3/12">
          <div>
            <l-image-upload
              v-model="integrator.logo"
              class="h-12 w-12"
              :class="{ invisible: !integrator.logo }"
              drag-drop
              disabled
            />
          </div>
          <div class="pl-4">{{ integrator.name }}</div>
        </div>
        <div class="w-4/12">{{ integrator.token }}</div>
        <div class="w-1/12">
          {{ formattedDate(integrator.creationTime) }}
        </div>
        <div class="flex w-3/12">
          <div
            class="flex max-w-min p-2 rounded-lg"
            :class="getClass(integrator.status)"
          >
            <icon class="mr-1" :name="getIcon(integrator.status)" />
            <div>{{ getStatus(integrator.status) }}</div>
          </div>
          <div
            v-if="integrator.status === 'pending'"
            class="flex rounded-lg border border-violet text-violet ml-4 px-2 items-center cursor-pointer"
            @click="passIntegratorToProd(integrator.id)"
          >
            {{ $t('integrators.pass-prod') }}
          </div>
        </div>
        <div class="flex w-1/12 justify-end">
          <icon
            name="external"
            class="w-6 h-6 text-violet cursor-pointer"
            @click="goToDevPortal(integrator.userId)"
          />
        </div>
      </div>
    </l-card>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'pinia'

import { lastUtils } from '@last/core'
import { Icon, LCard, LInput } from '@last/core-ui/paprika'
import LImageUpload from '@last/core-ui/v3/components/LImageUpload.vue'

import api from '@/api'
import { useAuthStore } from '@/store/auth.ts'

const CLASS = {
  prod: 'prod',
  test: 'test',
  pending: 'pending'
}

const ICON = {
  prod: 'check-circle',
  test: 'prohibit',
  pending: 'alert'
}

export default {
  name: 'Integrators',
  components: {
    LCard,
    Icon,
    LInput,
    LImageUpload
  },
  props: {},
  data() {
    return {
      query: '',
      integrators: []
    }
  },
  computed: {
    ...mapState(useAuthStore, ['accessToken']),
    filteredIntegrators() {
      if (!this.query) return this.integrators
      return this.integrators.filter(integrator => {
        return lastUtils.fuzzy(integrator.name, this.query)
      })
    }
  },
  async mounted() {
    await this.refreshIntegrators()
  },
  methods: {
    goToDevPortal(userId) {
      window.open(
        `${import.meta.env.VITE_LAST_DEVELOPER_URL}/support/login?supportToken=${this.accessToken}&userId=${userId}&tester=true`
      )
    },
    formattedDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    getClass(status) {
      return CLASS[status]
    },
    getStatus(status) {
      if (!status) return ''
      return this.$t(`integrators.${status}`)
    },
    getIcon(status) {
      return ICON[status]
    },
    async refreshIntegrators() {
      const response = await api.get('/support/integrators')
      this.integrators = response.data
    },
    async passIntegratorToProd(integratorId) {
      this.$ldialog({
        title: this.$t('integrators.pass-prod-title'),
        content: this.$t('integrators.pass-prod-content'),
        mainLabel: this.$t('ctas.apply'),
        secondaryLabel: this.$t('ctas.cancel'),
        icon: 'alert',
        onConfirm: async () => {
          await api.put(`/support/integrators/${integratorId}/allow-prod`)
          await this.refreshIntegrators()
        }
      })
    }
  }
}
</script>

<style scoped>
.prod {
  color: rgb(33, 190, 203);
  background-color: rgb(194, 241, 245);
}
.test {
  color: rgb(168, 168, 200);
  background-color: rgb(243, 243, 247);
}
.pending {
  color: rgb(229, 174, 0);
  background-color: rgb(255, 249, 229);
}
</style>
