<template>
  <div
    v-click-outside="() => (showCalendar = false)"
    class="w-full relative flex justify-center"
  >
    <div
      class="bg-gray-100 flex h-full w-full rounded-small justify-center leading-tight focus-within:border-lblue-500 items-center pr-3 cursor-pointer"
      @click="handleOpen"
    >
      <div
        v-if="modelValue"
        class="px-4 py-3 w-full text-blue whitespace-nowrap"
      >
        {{ showTime ? $filters.date(modelValue) : $filters.day(modelValue) }}
      </div>
      <div
        v-else
        class="px-4 whitespace-no-wrap ellipsis py-3 w-full text-gray-400"
      >
        {{ placeholder }}
      </div>
      <icon
        name="arrow-down"
        class="arrow text-violet right-0"
        :class="{
          rotated: showCalendar
        }"
      />
    </div>
    <transition name="fade">
      <calendar
        v-if="showCalendar"
        :selected-date="modelValue"
        class="bg-white rounded-xl shadow absolute mt-4 top-full z-10"
        :show-time="showTime"
        show-selectors
        :only-future="onlyFuture"
        @close="showCalendar = false"
        @update-selected-date="updateDate"
      ></calendar>
    </transition>
  </div>
</template>

<script>
import moment from 'moment'
import Icon from '@last/core-ui/v3/components/Icon.vue'
import Calendar from '@last/core-ui/v3/components/Calendar.vue'

export default {
  name: 'DateSelector',
  components: {
    Icon,
    Calendar
  },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    onlyFuture: {
      type: Boolean,
      default: false
    },
    showTime: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showCalendar: false
    }
  },
  computed: {},
  watch: {
    date(date) {
      this.$emit('update:model-value', date)
    }
  },
  methods: {
    updateDate(date) {
      this.$emit('update:model-value', moment(date).format('YYYY-MM-DD HH:mm'))
      this.showCalendar = false
    },
    handleOpen() {
      this.showCalendar = !this.showCalendar
    }
  }
}
</script>

<style lang="scss" scoped>
.arrow {
  transition: all 0.5s;
}
.rotated {
  transform: rotate(180deg);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
